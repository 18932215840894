import React from "react";
import imageImpower from "../Assets/shiny-circles-lit-tablet.jpg";
import imagefuture from "../Assets/OE7IMX0.jpg";
import team1 from "../Assets/WhatsApp Image 2025-01-28 at 20.38.07.jpeg";
import team2 from "../Assets/team2.jpg";
import team3 from "../Assets/WhatsApp_Image_2025-01-24_at_12.55.02_20abc3f5-removebg-preview (1).png";
import TeamCard from "../components/TeamCard/TeamCard";
import "../pages/Common.css";
import about from '../Assets/about.jpeg'
const About = () => {
  return (
    <div className="About_portion">
      <div className="servicesImages"><img src={about} loading="lazy" alt="about" /></div>
      <div className="About_Us">
        <h1 className="about_heading">ABOUT US</h1>
        {/* <h2 className="about_paragraph">DREAM BIG BUILD BIGGER</h2> */}
      </div>
      <div className="Empowering_portion">
        <div className="text_portion">
          <h1 className="about_paragraph">Empowering Innovation</h1>
          <h2 className="about_subheading">Our Mission to Revolutionize the Business Landscape</h2>
          <p className="about_paragraph_sub_right">
            At Kepler Voice, our mission is to provide businesses with
            innovative and sustainable Business and IT Solutions to optimize
            their operations and make a positive impact globally. We offer
            customized software solutions that empower businesses to achieve
            their full potential and operate more efficiently in the
            ever-changing market. However, our mission is not just about
            improving business operations but also prioritizing sustainability
            and corporate responsibility in all our operations. We believe in
            leading by example and contributing to a more equitable world. Our
            dedication to innovation, sustainability, and social responsibility
            enables us to be a powerful force for positive change in the world,
            creating a brighter future for all.
          </p>
        </div>
        <div className="image_portion">
          <img src={imageImpower} loading="lazy" alt="imageimpower" className="image_emporing" />
        </div>
      </div>
      <div className="Transitioning_portion Empowering_portion">
        <div className="image_portion">
          <img src={imagefuture} loading="lazy" alt="imagefuture" className="image_emporing" />
        </div>
        <div className="text_portion">
          <h1 className="about_paragraph">Transitioning to a Smarter Future</h1>
          <h2 className="about_subheading">Our Innovative Solutions for Business Operations</h2>
          <p className="about_paragraph_sub_left">
            Our world is constantly changing, and technology is driving that
            change faster than ever. At our company, we recognize the need to
            stay at the forefront of this transition and help our clients
            navigate the challenges and opportunities it presents. We believe
            that adaptability is key to success in this rapidly changing
            landscape, and we are committed to providing innovative,
            cutting-edge software solutions that enable our clients to stay
            ahead of the curve. Whether it’s through AI-powered chatbots,
            cloud-based call centre software, or advanced analytics tools, we
            are dedicated to helping businesses embrace the transition to new
            technologies and thrive in the years to come.
          </p>
        </div>
      </div>

      <div className="Ourteam_portion">
        <h1 className="about_heading">OUR TEAM</h1>
        <p className="about_paragraph_Team">
        Our team is led by three experienced and dedicated directors: PRANIT PANDEY, RAJEEV LOCHAN, and DISHA PAL. With a combined experience of over 15 years in the industry, Pranit Pandey and Rajeev Lochan bring a wealth of knowledge and expertise to our organization. Additionally, we are proud to have Disha Pal on board as a director, with 9 years of work experience. Disha is an expert in her field, with a proven track record of delivering exceptional results, particularly in collaboration with banks.  

Together, our leadership team is committed to providing the highest quality solutions and services to our clients. We are passionate about helping businesses unlock their full potential and achieve lasting success.
        </p>
        <div className="card_grid_p">
          <TeamCard
            test_name={"Mr Rajeev Lochan"}
            team1_image={team1}
            role={"Director"}
            test_des={"LINKEDIN"}
          />

          <TeamCard
            test_name={"Mr Pranit Pandey"}
            team1_image={team2}
            role={"Director"}
            test_des={"LINKEDIN"}
          />

          <TeamCard
            test_name={"Disha Pal"}
            team1_image={team3}
            role={"Director"}
            test_des={"https://www.linkedin.com/in/disha-pal-8054092ab?utm_source=share&utm_campaign=share_via&utm_content=profile&utm_medium=android_app"}
          />
        </div>
      </div>
    </div>
  );
};

export default About;
