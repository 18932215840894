import React from 'react'
import "../Servicecard/service.css"
import { Link } from 'react-router-dom'

const ServiceCard = ({icon,heading,paragraph,goto}) => {
  return (
    <div className='service_card_'>
        <div className='icon_content_portion'>
            <div className='lcon_service'>
                <img src={icon} loading='lazy' alt='servicepic' className='icon_class_'/>
            </div>
         </div>
         <div className='content_service_portion'>
         <h1 className='service_heading' style={{fontSize:"35px"}}>{heading}</h1>
        <p className='service_paragrap'>{paragraph}</p>
       <Link to={goto} className='readmo' alt="readmore"><button className='readmore'>ReadMore</button></Link>
    </div>
    </div>
  )
}

export default ServiceCard